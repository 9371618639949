import React, { useEffect } from "react";
import { Accordion } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { faAsterisk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppSelector } from "@hooks/index";
import { getGapsInCredList } from "@services/manage-documents/GapsInCred";
import {
  IGapsInCredChildModel,
  IGapsInCredParentModel,
} from "@helpers/interfaces/manageDocuments/GapsInCred";
import NoContentSection from "@common/no-content-section/NoContentSection";
import Spinner from "@common/spinner/Spinner";
import GapsInCredDocumentItem from "./GapsInCredDocumentItem";
import styles from "./GapsInCred.module.scss";
import { IGapsIndCredProps } from "@helpers/uiInterfaces/manage-documents/gaps-in-cred";
import FormCheckboxControl from "@common/form-controls/FormCheckboxControl";
import toastTypes from "@constants/toast-types";
import messages from "@constants/messages";
import { toastMessageActions } from "@features/ui/ToastMessage";

const GapsInCred = (props: IGapsIndCredProps) => {
  const {
    enableSelecton,
    checkedRows,
    setCheckedRows,
    selectedRows,
    setSelectedRows,
  } = props;
  const dispatch = useDispatch<any>();

  const userRecord = useAppSelector(
    (state) => state.authenticationRecord.userRecord
  );
  const gapsInCredentialingUIState = useAppSelector(
    (state) => state.ui.gapsInCredentialing
  );
  const employeeState = useAppSelector((state) => state.employeeFiles.employee);
  const gapsInCredState = useAppSelector(
    (state) => state.manageDocuments.gapsInCred
  );
  const gapsInCredListData = gapsInCredState.gapsInCredListData;

  useEffect(() => {
    loadGapsInCredList();
  }, []);

  async function loadGapsInCredList() {
    await dispatch(
      getGapsInCredList(
        userRecord.organizationId,
        gapsInCredentialingUIState.hrmrUserId ||
          employeeState.employeeData.hrmrUserId
      )
    );
  }

  function renderEmployeeNameLabel() {
    return (
      <div className={styles.employeeNameLabelSection}>
        <div className={styles.employeeIconContainer}>
          <FontAwesomeIcon icon={faUser} />
        </div>
        <label className={styles.employeeName}>
          <strong>{gapsInCredentialingUIState.employeeName}</strong>
        </label>
      </div>
    );
  }

  function renderAsterisk(required: boolean) {
    if (required === false) {
      return;
    }

    return (
      <FontAwesomeIcon icon={faAsterisk} className={styles.asteriskIcon} />
    );
  }

  function renderDocumentsCountLabel(docCount: number, expiredCount: number) {
    // if total documents are not 0 and expired documents and total documents are equal in number then don't render documentCountLabel
    // if (docCount !== 0 && docCount === expiredCount) {
    //   return;
    // }

    let docCountLabelClassName = styles.docCountLabel;

    if (docCount === 0) {
      docCountLabelClassName += ` ${styles.zerDocument}`;
    }

    // let _count = docCount - expiredCount;

    return (
      <label className={docCountLabelClassName}>{docCount} Document(s)</label>
    );
  }

  function renderExpiredDocumentsCountLabel(expiredCount: number) {
    if (expiredCount === 0) {
      return;
    }

    return (
      <label className={`${styles.docCountLabel} ${styles.expiredCountLabel}`}>
        {expiredCount} Expired Document(s)
      </label>
    );
  }

  function renderNotApplicableCountLabel(notApplicableCount: number) {
    if (notApplicableCount === 0) {
      return;
    }
    return (
      <label
        className={`${styles.docCountLabel} ${styles.notApplicableCountLabel}`}
      >
        {notApplicableCount} Not Applicable Document(s)
      </label>
    );
  }

  function renderAccordionHeader(item: IGapsInCredParentModel, index: number) {
    return (
      <Accordion.Header
        className={`accordion-header ${styles.gapsInCredAccordionHeader}`}
      >
        {renderCheckbox(item, index)}
        {renderAsterisk(item.isRequired)}
        <label className={styles.activityName}>{item.activityName}</label>
        {renderDocumentsCountLabel(item.documentsCount, item.expiredCount)}
        {renderExpiredDocumentsCountLabel(item.expiredCount)}
        {renderNotApplicableCountLabel(item.notApplicableCount)}
      </Accordion.Header>
    );
  }

  const renderCheckbox = (item: IGapsInCredParentModel, index: number) => {
    if (
      enableSelecton &&
      checkedRows &&
      setCheckedRows &&
      selectedRows &&
      setSelectedRows
    ) {
      const enableSelectionControlAttributes = {
        label: "",
        name: "enableSelection",
        checked: checkedRows.includes(index),
        disableTopBottomMargin: true,
        onChange(event: React.ChangeEvent<HTMLInputElement>) {
          if (event.target.checked) {
            if (item.documentsCount === 0) {
              dispatch(
                toastMessageActions.showToast({
                  message: messages.noDocumentToSelect,
                  type: toastTypes.WARNING,
                })
              );
              return;
            }

            const checkedArray = [...checkedRows, index]; // Add current index to checkedRows
            const filteredDocs = item.gapInCredDoc.filter(
              (doc) => doc.filePath && doc.uri
            );
            const updatedDocs = filteredDocs.map((doc) => ({
              ...doc,
              activitysequence: item.activitysequence,
              activityName: item.activityName,
              activityId: item.activityId,
            }));

            const selectedData = [...selectedRows, ...updatedDocs]; // Add updated documents to selectedRows
            setCheckedRows(checkedArray);
            setSelectedRows(selectedData);
          } else {
            const checkedArray = [...checkedRows];
            const selectedData = [...selectedRows];
            const uncheckedIndex = checkedArray.indexOf(index);

            if (uncheckedIndex !== -1) {
              // Remove the specific item from checkedRows
              checkedArray.splice(uncheckedIndex, 1);

              // Remove corresponding documents from selectedData
              const activityIdToRemove = item.activityId; // Assuming you want to remove by activityId
              const uncheckedData = selectedData.filter(
                (data) => data.activityId !== activityIdToRemove
              );

              setCheckedRows(checkedArray);
              setSelectedRows(uncheckedData);
            }
          }
        },
      };
      return (
        <div className={styles.checkbox}>
          <FormCheckboxControl {...enableSelectionControlAttributes} />
        </div>
      );
    }
  };

  function renderGapsInCredDocumentItem(
    document: IGapsInCredChildModel,
    index: number
  ) {
    const gapsInCredDocumentItemAttributes = {
      data: document,
      key: index,
    };

    return <GapsInCredDocumentItem {...gapsInCredDocumentItemAttributes} />;
  }

  function renderAccordionBody(item: IGapsInCredParentModel) {
    if (item.totalDocCount === 0) {
      return (
        <Accordion.Body className="accordion-body">
          <NoContentSection />
        </Accordion.Body>
      );
    }

    return (
      <Accordion.Body
        className={`accordion-body ${styles.gapsInCredDocumentList}`}
      >
        {item.gapInCredDoc.map((document, index) =>
          renderGapsInCredDocumentItem(document, index)
        )}
      </Accordion.Body>
    );
  }

  function renderGapsInCredAccordion(
    item: IGapsInCredParentModel,
    index: number
  ) {
    let eventKey = String(item.activityId);

    const trainingListAccordionAttributes = {
      key: index,
      className: "application-accordion",
    };

    return (
      <Accordion {...trainingListAccordionAttributes}>
        <Accordion.Item eventKey={eventKey}>
          {renderAccordionHeader(item, index)}
          {renderAccordionBody(item)}
        </Accordion.Item>
      </Accordion>
    );
  }

  function renderGapsInCredList() {
    if (gapsInCredListData.length === 0) {
      return <NoContentSection />;
    }

    const gapsInCredListDataClone = [...gapsInCredListData];

    return (
      <div className={styles.gapsInCredListSection}>
        {gapsInCredListDataClone
          .sort((a, b) => {
            // If 'a' or 'b' has activitysequence of 0 or null, move it to the bottom
            if (a.activitysequence === 0 || a.activitysequence === null)
              return 1;
            if (b.activitysequence === 0 || b.activitysequence === null)
              return -1;

            // Otherwise, sort by 'activitysequence'
            return a.activitysequence - b.activitysequence;
          })
          .map((item, index) => renderGapsInCredAccordion(item, index))}
      </div>
    );
  }

  if (gapsInCredState.loading === true) {
    return <Spinner />;
  }

  return (
    <div id={styles.gapsInCredMain}>
      {renderEmployeeNameLabel()}
      {renderGapsInCredList()}
    </div>
  );
};

export default GapsInCred;
