import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import RouteComponent from "@routes/RouteComponent";
import { authenticationActions } from "@features/authentication/Authentication";
import messages from "@constants/messages";
import routesNames from "@constants/routes-names";
import ToastMessage from "@common/toast-message/ToastMessage";
import Footer from "@common/footer/Footer";
import Navbar from "@common/Navbar";
import Sidebar from "@common/Sidebar";
import ErrorDialog from "@common/error-dialog/ErrorDialog";
import { getApplicationStorage } from "@utilities/storage";
import { getPageTitleByLocation } from "@utilities/page-title";
import { useAppSelector } from "./hooks";
import { decryptCipherValueIntoPlainValue } from "./utilities";

function App() {
  const dispatch = useDispatch<any>();
  const location = useLocation();

  const displayAuthorizationFailed = useAppSelector(
    (state) => state.ui.commonInterfaces.displayAuthorizationFailedDialog
  );

  // if user record is already available in application storage
  // simple store it in redux store as well
  useEffect(() => {
    const applicationStorage = getApplicationStorage();

    if (applicationStorage === null) {
      return;
    }
    dispatch(authenticationActions.updateUserRecord(applicationStorage));
  }, []);

  // handling storage event (whenever storage got updated, this function will get triggered)
  useEffect(() => {
    window.addEventListener("storage", function (event) {
      updateReduxStore(event);
    });

    return () => {
      window.removeEventListener("storage", function (event) {
        updateReduxStore(event);
      });
    };
  }, []);

  function checkIfRenderLayout() {
    if (
      location.pathname === routesNames.employeeCredentialingProfileRoute ||
      location.pathname === routesNames.thankyouPageRoute ||
      location.pathname === routesNames.errorPageRoute
    ) {
      return true;
    }

    return false;
  }

  function updateReduxStore(event: any) {
    const updatedApplicationStorage = event.newValue;
    const decryptedApplicationStorage = decryptCipherValueIntoPlainValue(
      updatedApplicationStorage
    );
    dispatch(
      authenticationActions.updateUserRecord(decryptedApplicationStorage)
    );
    window.location.reload();
  }

  function renderPageTitle() {
    const pageTitle = getPageTitleByLocation(location);

    return (
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
    );
  }

  function renderAuthorizationFailedDialog() {
    if (displayAuthorizationFailed === false) {
      return;
    }

    const errorDialogAttributes = {
      open: displayAuthorizationFailed,
      message: messages.authorizationFailedMessage,
    };

    return <ErrorDialog {...errorDialogAttributes} />;
  }

  // show navbar, sidebar and footer only if current path is employee-credential-profile path
  function renderNavbar() {
    if (checkIfRenderLayout() === true) {
      return;
    }

    return <Navbar />;
  }

  function renderSidebar() {
    if (checkIfRenderLayout() === true) {
      return;
    }

    return <Sidebar />;
  }

  function renderFooter() {
    if (checkIfRenderLayout() === true) {
      return;
    }

    return <Footer />;
  }

  return (
    <ToastMessage>
      {renderPageTitle()}
      <div className="wrapper">
        {renderNavbar()}
        {renderSidebar()}
        <div
          className={
            checkIfRenderLayout() === true
              ? "employee-credential-content-wrapper"
              : "content-wrapper"
          }
        >
          <RouteComponent />
        </div>
        {renderFooter()}
        {renderAuthorizationFailedDialog()}
      </div>
    </ToastMessage>
  );
}

export default App;
