import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  IDepartmentPaginationModel,
  IDepartmentModel,
  IAddChecklistModel,
} from "@helpers/interfaces/departments/Department";

const initialState = {
  departmentData: {} as IDepartmentModel,
  departmentListData: {} as IDepartmentPaginationModel,
  departmentchecklist: [] as Array<IAddChecklistModel>,
  allDepartmentList: [] as Array<IDepartmentModel>,
  error: null as any,
  loading: false,
  editorLoading: false,
};

const department = createSlice({
  name: "department",
  initialState,
  reducers: {
    updateDepartmentData: (state, action: PayloadAction<IDepartmentModel>) => {
      state.departmentData = action.payload;
    },

    updateDepartmenListData: (
      state,
      action: PayloadAction<IDepartmentPaginationModel>
    ) => {
      state.departmentListData = action.payload;
    },

    updateAllDepartmentChecklistData: (
      state,
      action: PayloadAction<Array<IAddChecklistModel>>
    ) => {
      state.departmentchecklist = action.payload;
    },

    updateAllDepartmentList: (
      state,
      action: PayloadAction<Array<IDepartmentModel>>
    ) => {
      state.allDepartmentList = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    updateEditorLoading: (state, action: PayloadAction<boolean>) => {
      state.editorLoading = action.payload;
    },

    resetEditorState: (state) => {
      state.editorLoading = false;
      state.error = null;
    },
  },
});

export const departmentActions = department.actions;
export default department.reducer;
