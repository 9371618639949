import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IProfLiabilityInsuranceModel } from '@helpers/interfaces/credentialProfile/ProfLiabilityInsuranceTab';

const initialState = {
  profLiabilityInsuranceData: {} as IProfLiabilityInsuranceModel,
  profLiabilityInsuranceDataList: [] as Array<IProfLiabilityInsuranceModel>,
  error: null as any,
  loading: false,
  editorLoading: false
};

const profLiabilityInsurance = createSlice({
  name: 'profLiabilityInsurance',
  initialState,
  reducers: {

    updateProfLiabilityInsuranceData: (state, action: PayloadAction<IProfLiabilityInsuranceModel>) => {
      state.profLiabilityInsuranceData = action.payload;
    },

    updateProfLiabilityInsuranceDataList: (state, action: PayloadAction<Array<IProfLiabilityInsuranceModel>>) => {
      state.profLiabilityInsuranceDataList = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    updateEditorLoading: (state, action: PayloadAction<boolean>) => {
      state.editorLoading = action.payload;
    },

    resetEditorState: (state) => {
      state.editorLoading = false;
      state.error = null;
      state.profLiabilityInsuranceData = {} as IProfLiabilityInsuranceModel;
    }

  }
});

export const profLiabilityInsuranceActions = profLiabilityInsurance.actions;
export default profLiabilityInsurance.reducer;