import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  IDisclosureModel,
  IDisclosureSignedAttsModel,
} from "@helpers/interfaces/credentialProfile/Disclosures";

const initialState = {
  disclosuresData: [] as Array<IDisclosureModel>,
  disclosuresDataByAttsId: [] as Array<IDisclosureModel>,
  disclosuresSignedData: [] as Array<IDisclosureSignedAttsModel>,
  credProfApplicationData: "",
  error: null as any,
  loading: false,
};

const disclosures = createSlice({
  name: "disclosures",
  initialState,
  reducers: {
    updateDisclosuresData: (
      state,
      action: PayloadAction<Array<IDisclosureModel>>
    ) => {
      state.disclosuresData = action.payload;
    },

    updateDisclosuresSignedAttsDataByAttsId: (
      state,
      action: PayloadAction<Array<IDisclosureModel>>
    ) => {
      state.disclosuresDataByAttsId = action.payload;
    },

    updateDisclosuresSignedAttsData: (
      state,
      action: PayloadAction<Array<IDisclosureSignedAttsModel>>
    ) => {
      state.disclosuresSignedData = action.payload;
    },

    updateCredProdApplicationData: (state, action: PayloadAction<string>) => {
      state.credProfApplicationData = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    resetState: (state) => {
      state.loading = false;
      state.error = null;
      state.disclosuresDataByAttsId = [] as Array<IDisclosureModel>;
      state.disclosuresSignedData = [] as Array<IDisclosureSignedAttsModel>;
      state.disclosuresData = [] as Array<IDisclosureModel>;
    },
  },
});

export const disclosuresActions = disclosures.actions;
export default disclosures.reducer;
