import { useState, useEffect, useCallback } from "react";
import ContentHeader from "@common/ContentHeader";
import styles from "./DocuSignReport.module.scss";
import PaginatedTable from "@common/paginated-table/PaginatedTable";
import NoContentSection from "@common/no-content-section/NoContentSection";
import Spinner from "@common/spinner/Spinner";
import { useDispatch } from "react-redux";
import {
  exportDocuSignReports,
  getAllDocuSignReports,
} from "@services/reports/DocuSignReports";
import { useAppSelector } from "@hooks/index";
import moment from "moment";
import docuSignReportsColumns from "@constants/json-data/table-columns/reports/docuSignReportsColumn";
import { createDocuSignReport, parseDocuSignReportsRows } from "./utilities";
import { exportToExcel } from "@utilities/import-export-excel";
import DocuSignFilterEditor from "./DocuSignReportFilterEditor";
import { IDocuSignReportSearchParams } from "@helpers/interfaces/docuSignReport";
import { setDefaultDocuSignReportSearchParams } from "@helpers/configMiddlewares/docuSignReport";
import BootstrapSpinner from "react-bootstrap/Spinner";
import SelectedFilters from "./SelectedFilters/SelectedFilters";

const DEFAULT_PAGE_LIMIT = 10;

const DocuSignReport = () => {
  const [displayFilterEditor, setDisplayFilterEditor] =
    useState<boolean>(false);
  const [paginationState, setPaginationState] = useState({
    page: 1,
    limit: DEFAULT_PAGE_LIMIT,
    filterData: setDefaultDocuSignReportSearchParams(),
  });
  const [reportExportLoading, setReportExportLoading] = useState(false);
  const dispatch = useDispatch<any>();
  const docuSignReportState = useAppSelector(
    (state) => state.reports.docuSignReport
  );
  const docuSignReportList = docuSignReportState.docuSignReportListData?.page;

  const loadReportData = useCallback(
    async (
      pageNumber: number,
      pageLimit: number,
      filterData: IDocuSignReportSearchParams
    ) => {
      const { fromDate, toDate } = filterData;
      await dispatch(
        getAllDocuSignReports(pageNumber, pageLimit, fromDate, toDate)
      );

      setPaginationState({
        ...paginationState,
        page: pageNumber,
        limit: pageLimit,
        filterData: filterData,
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  useEffect(() => {
    loadReportData(1, DEFAULT_PAGE_LIMIT, paginationState.filterData);
  }, [paginationState.filterData, loadReportData]);

  const handleExportDocuSignReport = async () => {
    setReportExportLoading(true);

    const response = await dispatch(
      exportDocuSignReports(
        paginationState.filterData.fromDate,
        paginationState.filterData.toDate
      )
    );
    if (response.status === 200) {
      const timestamp = moment().format("YYYYMMDDhhmmss");
      const fileName = "DocuSignReport" + timestamp;

      const reportDataset = createDocuSignReport(response.data);

      await exportToExcel(reportDataset, fileName, "Report");
      setReportExportLoading(false);
    } else {
      setReportExportLoading(false);
    }
  };

  function renderHeader() {
    let contentNode: any = "Export Report";

    if (reportExportLoading === true) {
      contentNode = (
        <BootstrapSpinner
          animation="border"
          variant="dark"
          size="sm"
          className="me-2"
        />
      );
    }

    const filterControlAttributes = {
      title: "Apply filters",
      className: "btn btnorg me-2 float-end w120",
      onClick() {
        setDisplayFilterEditor(true);
      },
    };

    const exportControlAttributes = {
      className: "btn btnorg float-end me-2 w120",
      onClick: handleExportDocuSignReport,
    };

    const selectedFiltersAttributes = {
      filters: paginationState.filterData,
      onFilterChange(data: IDocuSignReportSearchParams) {
        loadReportData(1, DEFAULT_PAGE_LIMIT, data);
      },
    };

    return (
      <div className="row align-items-center">
        <div className="col-md-7 col-xs-12 col-sm-12 d-flex">
          <SelectedFilters {...selectedFiltersAttributes} />
        </div>
        <div className="col-md-5 col-xs-12 col-sm-12">
          <button {...exportControlAttributes}>{contentNode}</button>
          <button {...filterControlAttributes}>Search & Filter</button>
        </div>
      </div>
    );
  }

  function renderContent() {
    if (!docuSignReportList || docuSignReportState.loading === true) {
      return <Spinner />;
    }

    if (docuSignReportList.length === 0) {
      return <NoContentSection />;
    }

    const paginatedTableAttributes = {
      columns: docuSignReportsColumns,
      rows: parseDocuSignReportsRows(docuSignReportList),
      currentPage: paginationState.page,
      totalRecord:
        docuSignReportState.docuSignReportListData?.paging?.totalItems,
      rowsPerPage: paginationState.limit,
      onPageLimitChange(limit: number) {
        loadReportData(1, limit, paginationState.filterData);
      },
      onPageChange(page: number) {
        loadReportData(page, paginationState.limit, paginationState.filterData);
      },
    };

    return (
      <div className="mt-4">
        <PaginatedTable {...paginatedTableAttributes} />
      </div>
    );
  }

  const contentHeaderAttributes = {
    title: "DocuSign Report",
    instructions: [],
  };

  function closeDocuSignReportFilterEditor() {
    setDisplayFilterEditor(false);
  }

  function renderFilterEditor() {
    if (displayFilterEditor === false) {
      return;
    }

    const documentsFilterEditorAttributes = {
      open: displayFilterEditor,
      filterData: paginationState.filterData,
      onClose: closeDocuSignReportFilterEditor,
      onSearch(data: IDocuSignReportSearchParams) {
        loadReportData(1, DEFAULT_PAGE_LIMIT, data);
      },
    };

    return <DocuSignFilterEditor {...documentsFilterEditorAttributes} />;
  }

  return (
    <div id={styles.docuSignReportMain}>
      <ContentHeader {...contentHeaderAttributes} />
      <div className={`container-fluid ${styles.docuSignReportContainer}`}>
        {renderHeader()}
        {renderContent()}
        {renderFilterEditor()}
      </div>
    </div>
  );
};

export default DocuSignReport;
