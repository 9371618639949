import React, { Suspense, useCallback, useEffect, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "@hooks/index";
import employeeEditorTabs from "@constants/json-data/employee-files/employee-files";
import Spinner from "@common/spinner/Spinner";
import styles from "./Emp_AddEditEditor.module.scss";
import {
  IAddEditEmployeeEditorProps,
  IEmployeeEditorTab,
} from "@helpers/uiInterfaces/add-edit-employee";
import { employeeActions } from "@features/employeeFiles/Employee";
import { addEditEmployeesActions } from "@features/employeeFiles/AddEditEmployees";
import { gapsInCredentialingUIActions } from "@features/ui/GapsInCredentialingUI";
import { getEmployeeData } from "@services/employeeFiles";
import { renderPrefix } from "./utilities";

const EmployeeAddEditTab = React.lazy(() => import("./Emp_AddEditTab"));
const EditEmployeeExclusions = React.lazy(
  () => import("./Edit_EmployeeExclusions")
);
const EditEmployeeTraining = React.lazy(
  () => import("./EditEmployeeTraining/Edit_EmployeeTraining")
);
const EditEmployeeOnboarding = React.lazy(
  () => import("./Edit_EmployeeOnboarding")
);
const EditEmployeeCredentialing = React.lazy(
  () => import("./Edit_EmployeeCredentialing")
);
const EditEmployeeGapsInCred = React.lazy(
  () => import("./Edit_EmployeeGapsInCred")
);
const EditEmployeePayer = React.lazy(() => import("./Edit_EmployeePayer"));
const EditEmployeePortal = React.lazy(() => import("./Emp_EditEmployeePortal"));
const EditEmployeePrivileging = React.lazy(
  () => import("./Edit_EmployeePrivileging")
);
const EditCredentialingCompletion = React.lazy(
  () =>
    import("./EditEmployeeCompleteCredentialing/Edit_CredentialingCompletion")
);
const FinalizeCredentialingReport = React.lazy(
  () => import("./FinalizeCredentialing/FinalizeCredentialingReport")
);
const EditDocuSignNotifications = React.lazy(
  () => import("./Edit_EmployeeDocuSignNotifications")
);
const EditCredentialingTracking = React.lazy(
  () => import("./EditCredentialingTracking/Edit_CredentialingTracking")
);

const AddEditEmployeeEditor: React.FC<IAddEditEmployeeEditorProps> = (
  props
) => {
  const { open, data, isTraining, onClose, updateSelectedData } = props;

  const dispatch = useAppDispatch();

  const employeeState = useAppSelector((state) => state.employeeFiles.employee);

  const [tabIndex, setTabIndex] = useState(1);
  const [showCredentialing, setShowCredentialing] = useState(false);
  const [showAddDocumentSection, setShowAddDocumentSection] = useState(false);
  const employeeData = useAppSelector(
    (state) => state.employeeFiles.employee.employeeData
  );

  useEffect(() => {
    if (isTraining && data) setTabIndex(4);
  }, [isTraining, data]);

  useEffect(() => {
    if (data) dispatch(getEmployeeData(data.hrmrUserId));
  }, [dispatch, data]);

  useEffect(() => {
    if (data) {
      const sliceState = {
        employeeName: `${data.firstName} ${data.lastName}`,
        hrmrUserId: data.hrmrUserId,
        enable: true,
      };

      dispatch(
        gapsInCredentialingUIActions.updateGapsInCredentialingState(sliceState)
      );
      dispatch(getEmployeeData(data.hrmrUserId));
    }
  }, [dispatch, data, tabIndex]);

  const onUnmount = useCallback(() => {
    dispatch(employeeActions.resetEditorState());
    dispatch(addEditEmployeesActions.resetState());
  }, [dispatch]);

  useEffect(() => {
    return () => onUnmount();
  }, [onUnmount]);

  function handleTabsNavigation(_tabIndex: number) {
    // navigate user only when employee record is available

    if (
      !employeeState.employeeData?.hrmrUserId &&
      _tabIndex !== 1 &&
      !isTraining
    ) {
      return;
    }

    if (showCredentialing) {
      setShowCredentialing(false);
    }
    if (showAddDocumentSection) {
      setShowAddDocumentSection(false);
    }
    setTabIndex(_tabIndex);
  }

  function renderEmployeeTab(tab: IEmployeeEditorTab, index: number) {
    let tabClassName = "nav-link";

    if (tabIndex === tab.index) {
      tabClassName += " active";
    }

    if (
      !employeeState.employeeData?.hrmrUserId &&
      tab.index !== 1 &&
      !isTraining
    ) {
      tabClassName += " disable";
    }

    const tabLinkControlAttributes = {
      className: tabClassName,
      role: "tab",
      key: index,
      onClick() {
        handleTabsNavigation(tab.index); // to disable click on tabs if the case if of create employee
      },
    };

    return (
      <li className="nav-item" key={index}>
        <a {...tabLinkControlAttributes}>{tab.label}</a>
      </li>
    );
  }

  function renderTabsContent() {
    const employeeTabContentAttributes = {
      tabIndex,
      data,
      onNavigate(_tabIndex: number) {
        setTabIndex(_tabIndex);
      },
      onClose,
    };

    const addEditEmployeeTabContentAttributes = {
      ...employeeTabContentAttributes,
      updateSelectedData,
    };

    const addEditEmployeeCredentialingTabContentAttributes = {
      ...addEditEmployeeTabContentAttributes,
      setShowCredentialing,
      showCredentialing,
    };

    const addEditEmployeeOnboardingTabContentAttributes = {
      ...addEditEmployeeTabContentAttributes,
      showAddDocumentSection,
      setShowAddDocumentSection,
    };

    switch (tabIndex) {
      case 1:
        return <EmployeeAddEditTab {...addEditEmployeeTabContentAttributes} />;
      case 2:
        return <EditEmployeePortal {...addEditEmployeeTabContentAttributes} />;
      case 3:
        return <EditEmployeeExclusions {...employeeTabContentAttributes} />;
      case 4:
        return <EditEmployeeTraining {...employeeTabContentAttributes} />;
      case 5:
        return (
          <EditEmployeeOnboarding
            {...addEditEmployeeOnboardingTabContentAttributes}
          />
        );
      case 6:
        return (
          <EditEmployeeCredentialing
            {...addEditEmployeeCredentialingTabContentAttributes}
          />
        );
      case 7:
        return <EditEmployeeGapsInCred {...employeeTabContentAttributes} />;
      case 8:
        return (
          <EditCredentialingCompletion {...employeeTabContentAttributes} />
        );
      case 9:
        return (
          <FinalizeCredentialingReport {...employeeTabContentAttributes} />
        );
      case 10:
        return <EditCredentialingTracking {...employeeTabContentAttributes} />;
      case 11:
        return <EditEmployeePrivileging {...employeeTabContentAttributes} />;
      case 12:
        return <EditEmployeePayer {...employeeTabContentAttributes} />;
      case 13:
        return <EditDocuSignNotifications {...employeeTabContentAttributes} />;
      default:
        break;
    }
  }

  function renderTabHeader() {
    return (
      <div className="thirdLevelTabs">
        <div className="scrollbar-2">
          <div className="sub_scrollbar">
            <ul className="nav nav-tabs mt-2" role="tablist">
              {employeeEditorTabs.map(
                (tab: IEmployeeEditorTab, index: number) =>
                  renderEmployeeTab(tab, index)
              )}
            </ul>
          </div>
        </div>

        <div className="tab-content">
          <Suspense fallback={<Spinner />}>{renderTabsContent()}</Suspense>
        </div>
      </div>
    );
  }

  function renderContent() {
    return (
      <Offcanvas.Body>
        <div id={styles.employeeEditorMain}>
          <div className="tab_form_content">
            <div className="inner_tabsWrapper">{renderTabHeader()}</div>
          </div>
        </div>
      </Offcanvas.Body>
    );
  }

  const employeeName = ` - ${employeeData?.firstName || ""} ${
    employeeData?.lastName || ""
  } ${employeeData?.credentialsName ? "," : ""} ${
    employeeData?.credentialsName ? employeeData.credentialsName : ""
  }`;
  const pronoun = renderPrefix(employeeData.pronouns);
  return (
    <Offcanvas
      show={open}
      onHide={onClose}
      placement="end"
      style={{ width: "85vw" }}
    >
      <Offcanvas.Header closeButton className="border-bottom">
        <Offcanvas.Title className={`w-100 ${styles.canvas}`}>
          <div className="d-flex justify-content-between">
            <div>
              Add/Edit Provider
              {employeeName}{" "}
              <span className={styles.pronoun}>
                {pronoun ? ` (${pronoun})` : ""}
              </span>
            </div>
            <div className={styles.idContainer}>
              <div>UID :- {employeeData.hrmrUserId}</div>
              <div>PID :- {employeeData.parentHRMRUserId || "--NA--"}</div>
            </div>
          </div>
        </Offcanvas.Title>
      </Offcanvas.Header>

      {renderContent()}
    </Offcanvas>
  );
};

export default AddEditEmployeeEditor;
