import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPerProf_PersonalInfoModel } from '@helpers/interfaces/credentialProfile/PerProfInfoTab';

const initialState = {
  personalInfoData: {} as IPerProf_PersonalInfoModel,
  error: null as any,
  loading: false
}

const personalInfoSlice = createSlice({
  name: 'personalInfo',
  initialState,
  reducers: {

    updatePersonalInfo: (state, action: PayloadAction<IPerProf_PersonalInfoModel>) => {
      state.personalInfoData = action.payload;
    },

    updateError: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },

    updateLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },

    resetState: (state) => {
      state.error = null;
      state.loading = false;
    }

  }
});

export const personalInfoAction = personalInfoSlice.actions;
export default personalInfoSlice.reducer;