import { Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Spinner from "@common/spinner/Spinner";
import { IRoute } from "helpers/uiInterfaces/common";
import initialRoutes from "./routes-data/initial-routes";
import { useAppSelector } from "@hooks/index";
import { getLoginUrlAccordingToEnv } from "@utilities/index";

const RouteComponent = () => {
  const userRecord = useAppSelector(
    (state) => state.authenticationRecord.userRecord
  );

  function renderRoute(route: IRoute, index: number) {
    const routeAttributes = {
      path: route.path,
      key: index,
      element:
        route.requiresAuth &&
        userRecord.isAuthenticated &&
        userRecord.isAuthenticated === false ? (
          <Navigate to={getLoginUrlAccordingToEnv()} /> // Redirect to login page if not authenticated
        ) : (
          route.element
        ),
    };

    return <Route {...routeAttributes} />;
  }

  return (
    <Suspense fallback={<Spinner fullscreen={true} />}>
      <Routes>
        {initialRoutes.map((route, index) => renderRoute(route, index))}
      </Routes>
    </Suspense>
  );
};

export default RouteComponent;
