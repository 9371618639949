import axiosClient from "@store/axiosClient";

import toastTypes from "@constants/toast-types";

import { toastMessageActions } from "@features/ui/ToastMessage";
import { npdbSetupActions } from "@features/setupIntegration/NPDB";

import { setDefaultNPDBSetupDetails } from "@helpers/configMiddlewares/setupIntegrations/NPDB";
import { INPDBSetupDetailsModel } from "@helpers/interfaces/setupIntegrations/NPDB";
import {
  handleCreateServiceResponse,
  handleServerInteractionError,
} from "@utilities/services";
import messages from "@constants/messages";

function showLoading(dispatch: any) {
  dispatch(npdbSetupActions.updateLoading(true));
}

function hideLoading(dispatch: any) {
  dispatch(npdbSetupActions.updateLoading(false));
}

function showEditorLoading(dispatch: any) {
  dispatch(npdbSetupActions.updateEditorLoading(true));
}

function hideEditorLoading(dispatch: any) {
  dispatch(npdbSetupActions.updateEditorLoading(false));
}

export const getNPDBSetupDetails = () => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      showLoading(dispatch);

      const response = await axiosClient.get(
        "api/OrganizationIntegrationSetup/GetNPDBSetupDetail"
      );
      let data = response.data;
      const status = response.status;

      if (status === 200) {
        if (!data) {
          response.data = setDefaultNPDBSetupDetails();
          resolve(response);
        } else {
          dispatch(npdbSetupActions.updateNPDBSetupDetails(data));
          resolve(response);
        }

        return hideLoading(dispatch);
      }

      dispatch(
        toastMessageActions.showToast({
          message: data.title,
          type: toastTypes.DANGER,
        })
      );

      hideLoading(dispatch);
    } catch (error) {
      handleServerInteractionError(dispatch);
      hideLoading(dispatch);
    }
  });
};

export const getNPDBSetupLogs = () => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      showEditorLoading(dispatch);

      const response = await axiosClient.get(
        "api/OrganizationIntegrationSetup/GetNPDBSetupLog"
      );
      const data = response.data;
      const status = response.status;

      if (status === 200) {
        dispatch(npdbSetupActions.updateNPDBSetupLogs(data));

        resolve(response);
        return hideEditorLoading(dispatch);
      }

      dispatch(
        toastMessageActions.showToast({
          message: data.title,
          type: toastTypes.DANGER,
        })
      );

      hideEditorLoading(dispatch);
    } catch (error) {
      handleServerInteractionError(dispatch);
      hideEditorLoading(dispatch);
    }
  });
};

export const saveNPDBDetails =
  (data: INPDBSetupDetailsModel) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        showLoading(dispatch);

        const response = await axiosClient({
          method: "post",
          url: "api/OrganizationIntegrationSetup/SaveNPDBSetup",
          data: `${JSON.stringify(data)}`,
        });
        const status = response.status;

        handleCreateServiceResponse(
          dispatch,
          npdbSetupActions,
          status,
          response.data
        );

        hideLoading(dispatch);
        resolve(response);
      } catch (error) {
        handleServerInteractionError(dispatch);
        hideLoading(dispatch);
      }
    });
  };

export const getNPDBPersonalInfo =
  (hrmrUserId: number) => async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        showLoading(dispatch);
        const response = await axiosClient.get(
          `api/NPDB/GetPersonalInfo?HRMRUserId=${hrmrUserId}`
        );
        let data = response.data;
        const status = response.status;

        if (status === 200) {
          dispatch(npdbSetupActions.updateNPDBPersonalInfo(data));
          return hideLoading(dispatch);
        }
        resolve(response);
        dispatch(
          toastMessageActions.showToast({
            message: data.title,
            type: toastTypes.DANGER,
          })
        );

        hideLoading(dispatch);
      } catch (error) {
        handleServerInteractionError(dispatch);
        hideLoading(dispatch);
      }
    });
  };

export const npdbOrderConfirm =
  (
    hrmrUserId: number,
    licenseIds: string,
    isLicensePresent: boolean,
    npdbType: string
  ) =>
  async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      showLoading(dispatch);
      try {
        const response = await axiosClient.post(
          `api/NPDB/NPDBOrderConfirm?HRMRUserId=${hrmrUserId}&PIPStateLicenseId=${licenseIds}&NoLicense=${isLicensePresent}&NPDBType=${npdbType}`
        );
        resolve(response);
        if (response.status === 200) {
          if (
            response.data &&
            response.data.status &&
            response.data.status === "InComplete"
          ) {
            dispatch(
              toastMessageActions.showToast({
                message: response.data.notification,
                type: toastTypes.DANGER,
              })
            );
            return hideLoading(dispatch);
          } else {
            dispatch(
              toastMessageActions.showToast({
                message: messages.orderPlacedSuccessfully,
                type: toastTypes.SUCCESS,
              })
            );
            return hideLoading(dispatch);
          }
        }

        dispatch(
          toastMessageActions.showToast({
            message:
              typeof response.data === "string"
                ? response.data
                : response.data.notification,
            type: toastTypes.DANGER,
          })
        );
        hideLoading(dispatch);
      } catch (error) {
        handleServerInteractionError(dispatch);
        hideLoading(dispatch);
      }
    });
  };

export const getNPDBAgentDetails = (orgId: string) => async (dispatch: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      showLoading(dispatch);
      let url = `api/NPDB/GetNPDBAgentDetails?`;
      if (orgId) {
        url += `&OrganizationId=${orgId}`;
      }
      const response = await axiosClient.get(url);
      const data = response.data;
      const status = response.status;

      if (status === 200) {
        dispatch(npdbSetupActions.updateNPDBAgentDetails(data));
        resolve(response);
        return hideLoading(dispatch);
      }

      dispatch(
        toastMessageActions.showToast({
          message: data,
          type: toastTypes.DANGER,
        })
      );

      hideLoading(dispatch);
    } catch (error) {
      handleServerInteractionError(dispatch);
      hideLoading(dispatch);
    }
  });
};

export const downloadNPDBReport =
  (orgId: string, npdbScanningId: number, hrmrUserId: number, source: string) =>
  async (dispatch: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        showLoading(dispatch);
        let url = `api/NPDB/DownloadNPDBReport?`;
        if (orgId) {
          url += `&OrganizationId=${orgId}`;
        }

        if (npdbScanningId) {
          url += `&NPDBScanningId=${npdbScanningId}`;
        }

        if (hrmrUserId) {
          url += `&HRMRUserId=${hrmrUserId}`;
        }

        if (source) {
          url += `&Source=${source}`;
        }

        const response = await axiosClient.get(url);
        const data = response.data;
        const status = response.status;
        resolve(response);
        if (status === 200) {
          return hideLoading(dispatch);
        }

        dispatch(
          toastMessageActions.showToast({
            message: data,
            type: toastTypes.DANGER,
          })
        );

        hideLoading(dispatch);
      } catch (error) {
        handleServerInteractionError(dispatch);
        hideLoading(dispatch);
      }
    });
  };
