import React, { useState, useEffect, useRef } from "react";
import { Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { ISingleSelectProps } from "@helpers/uiInterfaces/common";
import { ISelectDefaultOptionDataset } from "@helpers/uiInterfaces/form-controls";
import useOnClickOutside from "@hooks/useOnClickOutside";
import { findLabelByValue, handleFilterChange } from "./utilities";
import styles from "./SingleSelect.module.scss";

const SingleSelect: React.FC<ISingleSelectProps> = (props) => {
  const {
    data,
    value,
    placeholder,
    loading,
    disabled,
    disableDefaultSelectOption,
    defaultOptionDataset,
    error,
    dontShowArrow,
    onSelect,
    customOnFocus,
  } = props;

  const singleSelectContainerReference = useRef<HTMLDivElement>(null);
  const dataListMenuReference = useRef<HTMLDivElement>(null);

  const [displayDataListMenu, setDisplayDataListMenu] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [cursorPosition, setCursorPosition] = useState(-1);
  const [filteredList, setFilteredList] = useState(data);

  useOnClickOutside(singleSelectContainerReference, hideDataListMenu);

  useEffect(() => {
    setFilteredList(data);
  }, [data]);

  useEffect(() => {
    if (cursorPosition < 0) {
      return;
    }

    if (dataListMenuReference.current === null) {
      return;
    }

    const listItems = Array.from(dataListMenuReference.current.children);
    const listItem = listItems[cursorPosition] as HTMLLabelElement;

    if (typeof listItem !== "undefined") {
      scrollIntoView(listItem.offsetTop);
    }
  }, [cursorPosition]);

  useEffect(() => {
    const label = findLabelByValue(data, value);
    setSearchText(label);
  }, [value, data]);

  const scrollIntoView = (position: number) => {
    if (dataListMenuReference.current === null) {
      return;
    }

    dataListMenuReference.current.scrollTo({
      top: position,
      behavior: "smooth",
    });
  };

  function showDataListMenu() {
    setDisplayDataListMenu(true);
  }

  function hideDataListMenu() {
    // if user didn't selected any option, then simply clear the search text input field
    const label = findLabelByValue(data, value);
    if (label !== searchText) {
      setSearchText("");
    }

    setDisplayDataListMenu(false);
    setFilteredList(data);
  }

  const handleKeyDownEvent = (event: React.KeyboardEvent<HTMLInputElement>) => {
    let key = event.key;

    if (key === "Escape") {
      hideDataListMenu();
    }

    if (key === "ArrowUp") {
      handleUpArrowKeyEvent();
    }

    if (key === "ArrowDown") {
      handleDownArrowKeyEvent();
    }

    if (key === "Enter" && cursorPosition > -1) {
      handleEnterKeyEvent();
    }
  };

  const handleUpArrowKeyEvent = () => {
    let cursor = cursorPosition;

    if (cursor <= 0) {
      cursor = filteredList.length;
    }

    cursor--;

    setCursorPosition(cursor);
  };

  const handleDownArrowKeyEvent = () => {
    let cursor = cursorPosition;

    if (cursor === filteredList.length - 1) {
      cursor = -1;
    }

    cursor++;

    setCursorPosition(cursor);
  };

  const handleEnterKeyEvent = () => {
    const _data = filteredList[cursorPosition];

    handleOnSelectItem(_data);
  };

  const handleOnSelectItem = (item: any) => {
    onSelect(item);

    // setSearchText(item.label);
    setCursorPosition(-1);

    hideDataListMenu();
  };

  function handleInputField(event: React.ChangeEvent<HTMLInputElement>) {
    const _searchText = event.target.value;

    showDataListMenu();
    setSearchText(_searchText);
    setFilteredList(handleFilterChange(data, _searchText));
  }

  function handleInputFieldFocus(event: React.FocusEvent<HTMLInputElement>) {
    customOnFocus && customOnFocus();
    if (searchText === "") {
      return;
    }

    event.target.select();
  }

  function renderNoResultFoundContent() {
    return (
      <div className={`${styles.dataListMenu} ${styles.noResultFound}`}>
        <span className={styles.noResultFoundText}>
          No result found for your search.
        </span>
      </div>
    );
  }

  function renderDataListItem(item: any, index: number | string) {
    let dataItemClassName = styles.dataItem;

    if (cursorPosition === index) {
      dataItemClassName += ` ${styles.active}`;
    }

    if (item.value === value) {
      dataItemClassName += ` ${styles.selected}`;
    }

    if (item.isDisabled) {
      dataItemClassName += ` ${styles.disabled}`;
    }

    const dataListItemAttributes = {
      key: index,
      className: dataItemClassName,
      onClick() {
        if (!item.isDisabled) handleOnSelectItem(item);
      },
    };

    return <label {...dataListItemAttributes}>{item.label}</label>;
  }

  function renderDataListMenu() {
    if (displayDataListMenu === false) {
      return;
    }

    if (loading === true) {
      const dataListMenuAttributes = {
        className: `${styles.dataListMenu} ${styles.spinnerContainer}`,
        ref: dataListMenuReference,
      };

      return (
        <div {...dataListMenuAttributes}>
          <Spinner animation="border" />
        </div>
      );
    }

    if (filteredList.length === 0) {
      return renderNoResultFoundContent();
    }

    const dataListMenuAttributes = {
      className: styles.dataListMenu,
      ref: dataListMenuReference,
    };

    let defaultOption: ISelectDefaultOptionDataset = {
      label: "Please Select",
      value: 0,
    };

    if (typeof defaultOptionDataset !== "undefined") {
      defaultOption = defaultOptionDataset;
    }

    return (
      <div {...dataListMenuAttributes}>
        {/* rendering default 'Please Select option' */}
        {!disableDefaultSelectOption &&
          renderDataListItem(defaultOption, "default-option")}
        {filteredList.map((item, index) => renderDataListItem(item, index))}
      </div>
    );
  }

  function renderChevronIcon() {
    if (dontShowArrow) {
      return;
    }

    const chevronIconAttributes = {
      className: styles.carotIcon,
      onClick() {
        if (disabled === true) {
          return;
        }

        setDisplayDataListMenu((_displayDataListMenu) => !_displayDataListMenu);
      },
    };

    if (displayDataListMenu === false) {
      return <FontAwesomeIcon icon={faCaretDown} {...chevronIconAttributes} />;
    }

    return <FontAwesomeIcon icon={faCaretUp} {...chevronIconAttributes} />;
  }

  let singleSelectContainerClassName = styles.singleSelectMain;

  if (disabled === true) {
    singleSelectContainerClassName += ` ${styles.disabled}`;
  }

  if (error) {
    singleSelectContainerClassName += ` ${styles.error}`;
  }

  const singleSelectContainerAttributes = {
    className: singleSelectContainerClassName,
    ref: singleSelectContainerReference,
  };

  const singleSelectInputControlAttributes = {
    type: "text",
    placeholder: placeholder ? placeholder : "Please select",
    className: styles.singleSelectInput,
    value: searchText,
    disabled,
    onChange: handleInputField,
    onClick() {
      setDisplayDataListMenu((_displayDataListMenu) => !_displayDataListMenu);
    },
    onKeyDown: handleKeyDownEvent,
    onFocus: handleInputFieldFocus,
  };

  return (
    <div {...singleSelectContainerAttributes}>
      <input {...singleSelectInputControlAttributes} />
      {renderChevronIcon()}
      {renderDataListMenu()}
    </div>
  );
};

export default SingleSelect;
