import React, { Suspense, useState } from "react";
import { Offcanvas } from "react-bootstrap";
import { useAppSelector } from "@hooks/index";
import documentsCredentialingInstructions from "@constants/instructions/documents-credentialing";
import documentsCredentialingSubTabs from "@constants/json-data/manage-documents/documents-credentialing-subtabs";
import addEditDocumentEditorCallingFrom from "@constants/add-edit-document-editor-calling-from";
import { IDocumentsCredentialingEditorProps } from "@helpers/uiInterfaces/manage-documents";
import Spinner from "@common/spinner/Spinner";
import InstructionsDialog from "@common/instructions-dialog/InstructionsDialog";
import { ReactComponent as InformationIcon } from "@images/information-sign.svg";
import styles from "./DocumentsCredentialingEditor.module.scss";

const AddEditDocumentEditor = React.lazy(
  () => import("../addEditDocumentEditor/AddEditDocumentEditor")
);
const GapsInCred = React.lazy(() => import("../gapsInCred/GapsInCred"));
const DocComment = React.lazy(() => import("../docComment/DocComment"));

const DocumentsCredentialingEditor: React.FC<
  IDocumentsCredentialingEditorProps
> = (props) => {
  const { open, data, mode, onClose, onSave } = props;

  const gapsInCredentialingUIState = useAppSelector(
    (state) => state.ui.gapsInCredentialing
  );

  const [tabIndex, setTabIndex] = useState(1);
  const [displayInstructionsDialog, setDisplayInstructionsEditor] =
    useState(false);

  function handleTabsControlClick(_tabIndex: number) {
    if (gapsInCredentialingUIState.enable === false) {
      return;
    }

    setTabIndex(_tabIndex);
  }

  function renderTab(tab: any, index: number) {
    let tabClassName = "nav-link";

    if (tabIndex === tab.index) {
      tabClassName += " active";
    }

    if (tab.index === 2 && gapsInCredentialingUIState.enable === false) {
      tabClassName += " disable";
    }

    const tabLinkControlAttributes = {
      className: tabClassName,
      role: "tab",
      key: index,
      onClick() {
        handleTabsControlClick(tab.index);
      },
    };

    return (
      <li className="nav-item" key={index}>
        <a {...tabLinkControlAttributes}>{tab.label}</a>
      </li>
    );
  }

  function renderTabHeader() {
    return (
      <div className="thirdLevelTabs">
        <div className="scrollbar-2">
          <div className="sub_scrollbar mt-2">
            <ul className="nav nav-tabs" role="tablist">
              {documentsCredentialingSubTabs.map((tab, index) =>
                renderTab(tab, index)
              )}
            </ul>
          </div>
        </div>
      </div>
    );
  }

  function renderInstructionsControl() {
    if (tabIndex !== 1) {
      return;
    }

    const tooltipAttributes = {
      // eslint-disable-next-line no-useless-computed-key
      ["data-bs-toggle"]: "tooltip",
      //eslint-disable-next-line no-useless-computed-key
      ["data-bs-placement"]: "bottom",
    };

    const instructionsControlAttributes = {
      ...tooltipAttributes,
      title: "Instructions",
      className: styles.instructionsControl,
      onClick() {
        setDisplayInstructionsEditor(true);
      },
    };

    return (
      <button {...instructionsControlAttributes}>
        <InformationIcon className={styles.instructionIcon} />
      </button>
    );
  }

  function renderInstructionsDialog() {
    if (displayInstructionsDialog === false) {
      return;
    }

    const informationDialogAttributes = {
      instructions: documentsCredentialingInstructions,
      open: displayInstructionsDialog,
      onClose() {
        setDisplayInstructionsEditor(false);
      },
    };

    return <InstructionsDialog {...informationDialogAttributes} />;
  }

  function renderTabsContent() {
    const addEditDocumentEditorAttributes = {
      data,
      mode,
      callingFrom: addEditDocumentEditorCallingFrom.MANAGE_DOCUMENT,
      onClose,
      onSave,
    };

    switch (tabIndex) {
      case 1:
        return <AddEditDocumentEditor {...addEditDocumentEditorAttributes} />;
      case 2:
        return <GapsInCred />;
      case 4:
        return <DocComment />;
      default:
        break;
    }
  }

  function renderContent() {
    return (
      <Offcanvas.Body className={styles.documentsCredentialingEditorMain}>
        <div className="tab_form_content px-2">
          <div className="inner_tabsWrapper">{renderTabHeader()}</div>
        </div>

        <div>
          <Suspense fallback={<Spinner />}>{renderTabsContent()}</Suspense>
        </div>

        {renderInstructionsControl()}
        {renderInstructionsDialog()}
      </Offcanvas.Body>
    );
  }

  return (
    <Offcanvas
      show={open}
      onHide={onClose}
      placement="end"
      style={{ width: "70vw" }}
    >
      <Offcanvas.Header closeButton className="border-bottom">
        <Offcanvas.Title>Add / Edit Document</Offcanvas.Title>
      </Offcanvas.Header>

      {renderContent()}
    </Offcanvas>
  );
};

export default DocumentsCredentialingEditor;
