const messages = {
  somethingWentWrong: "Something went wrong. Try again later!",
  dataSuccessfullySaved: "Data successfully saved",
  dataSuccessfullyDeleted: "Data successfully deleted",
  dataSuccessfullyHidden: "Data successfully hidden",
  dataSuccessfullyUnHidden: "Data successfully unhidden",
  validationsErrorOccurred: "Validations error occurred. Please check!",
  sequenceSavedSuccessfully: "Sequence saved successfully",
  statusChangedSuccessfully: "Status changed successfully",
  documentTypeDuplicacyError:
    "Document for this selected document type already exists. Either hide previous document or change current document type.",
  documentDeletionMessage:
    "If you confirm, Document associated with this activity also get removed.",
  activityHidingMessage:
    "If you confirm, Document type and Verification type binded with this activity also get removed.",
  documentTypeHidingMessage:
    "If you confirm, Verification type binded with this document type also get removed.",
  invalidRedirectionLinkMessage:
    "Redirection link is invalid. Required query parameters are not available.",
  authenticationFailedMessage: "Authentication failed. Try again later!",
  authorizationFailedMessage:
    "Authorization token has been expired. Please go back to main application and re-authenticate yourself.",
  disclosuresConfirmationMessage:
    'If you confirm, Unresponded questions will automatically considered as "No".',
  deletionConfirmationMessage:
    "If you confirm, this set of data will get permanently deleted.",
  addServiceSiteRecordFirst:
    "Please add service site (current tab) record first.",
  npiNumberValidatedSuccessfully: "NPI Number Validated Successfully",
  npiNumberValidationFailed:
    "NPI Number Validation Failed. Check your NPI Number!",
  federalDEANumberValidatedSuccessfully:
    "Federal DEA Number Validated Successfully",
  federalDEANumberValidationFailed:
    "Federal DEA Number Validation Failed. Check your Federal DEA Number!",
  stateLicenseValidationFailed:
    "State License Number Validation Failed. Check your State License Number!",
  stateLicenseNumberValidatedSuccessfully:
    "State License Validated Successfully",
  npiABMSNumberValidatedSuccessfully: "ABMS Number Validated Successfully",
  documentSentSuccessfully: "Document Sent Successfully",
  trainingSchedulerDeleteConfirmation:
    "Do you really want to delete this Training Scheduler?",
  assignTrainingAndFavoriteIt:
    "This training has not been assigned. Do you want to mark it as favorite and assign it?",
  changesDoneSuccessfully: "Changes done successfully",
  documentTypeHiddenOrUnassigned:
    "Document Type is either hidden or unassigned",
  activityHiddenOrUnassigned:
    "Credentialing Activity is either hidden or unassigned",
  sendTrainingLinkToAllConfirmation:
    "By Clicking Send Training Link from here, you will be sending the selected training to all the employees in the organization. Are you sure you want to send it to all your employees?",
  sendTrainingLinkToDeptConfirmation:
    "By Clicking Send Training Link from here, you will be sending the selected training to all the employees who belong to the selected department in the organization. Are you sure you want to send it to all your employees?",
  emailRequiredForRequestReference:
    "Request for reference cannot be sent as email is not available for this reference contact. To complete this request please add an email address to this reference.",
  tabChangeMessage:
    "It looks like you have been editing something.If you leave before saving, your changes will be lost.",
  // disclosuresSaveWithoutSignMessage:
  //   "On saving this data the signature will not be saved, if you want to save the signature as well please click complete attestation instead",
  empPortalCredProfileCompletionMessage:
    "Thankyou for completing your Credentialing Profile.",
  noDataFound: "No Data Found",
  noDocUploaded: "Are you sure you want to proceed without adding document?",
  selectDeptMessage: "Please select a department first",
  employeeActivationWarningMessage:
    "If you prefer to make this employee ACTIVE, please remove the Termination Date from the Additional Information section.",
  orderPlacedSuccessfully: "Order Placed Successfully",
  orderCompletedSuccessfully: "Order Completed Successfully",
  lastAttsDateRequired: "Last Attestation Date is required to Sync CAQH",
  syncCAQHValidationError:
    "Make sure provider is active on roster and verify CAQH and last Attestation date.",
  doYouWantToAddProviderToRoster:
    "Do you want to add this provider to your CAQH roster?",
  selectAtleastOneTraining: "Please select atleast one training!",
  noSelectedTrainingOrAssignedTrainingWithDept:
    "There's no assigned training with this department or no training is selected",
  toAssignDeptNeedToAssignTraining:
    "To assign department you need to assign training to the organization.",
  toScheduleTrainingNeedToAssignTraining:
    "To schedule training you need to assign training to the organization.",
  trainingAssignmentNote:
    "Note: If you have removed any training from the previously Assigned Training List, the weekly reminders will be removed for these training. Are you sure you would like to continue?",
  fileNotFound: "File not Found!",
  hideApplicant: "Applicant hidden Successfully!",
  unhideApplicant: "Applicant unhidden Successfully!",
  hideInstance: "Credentialing tracking instance hidden Successfully!",
  unhideInstance: "Credentialing tracking instance unhidden Successfully!",
  hidePrivilegingDoc: "Privileging Document hidden Successfully!",
  unhidePrivilegingDoc: "Privileging Document unhidden Successfully!",
  approveApplicant: "Applicant Approved Sucessfully!",
  affiliationInstituteAlreadyExists:
    "This affiliated institution already exists.",
  noDocumentToSelect: "There's no document to merge in this category!",
  fillAllRequiredFieldsReqCred:
    "Please fill all the required fields in all four sections to save the data",
  credTrackingOrgDuplicateMessage:
    "This organization is already selected, please select another organization!",
};

export default messages;
