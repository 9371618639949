import {createSlice} from '@reduxjs/toolkit'
import axiosClient from '@store/axiosClient';

// Define a type for the slice state
interface profileState {
    url :string,
    lstresponse : []

}

// Define the initial state using that type
export const initialState :profileState={
    url :'',
    lstresponse : []
}

//Create Slice
const importExportSlice = createSlice({
    name: 'importExport',
    initialState,
    reducers :{
        updateURL:(state,{payload})=>{
            state.url =payload
        },
        getcreddata :(state,{payload}) =>{
            state.lstresponse = payload
        }
    }
})

export const importExportActions = importExportSlice.actions;
export const importExportSelector = (state: any) => state;
export default importExportSlice.reducer;

export  function DownloadFile(FolderName: string , FileName : string) { //sessionId : any
    return  async(dispatch : any ) => {
        try{
         const response =  await axiosClient.get('api/Exclusion/DownloadExclusionTemplate?FolderName=' + FolderName + '&FileName=' + FileName);
         const url = URL.createObjectURL(new Blob([response.data]));
         dispatch(importExportActions.updateURL(url));
         // dispatch(masterDataActions.updateExclusionState(response.data));
        
        } 
        catch(error){
            console.log(error);
        }
    }
} 


