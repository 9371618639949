const routesNames = {
  dashboardRoute: "/",
  authenticateRoute: "/authenticate",
  thankyouPageRoute: "/thankyou",
  errorPageRoute: "/error",
  credentialingProfileRoute: "/credentialing-profile",
  npdbRoute: "/npdb",
  psvRoute: "/psv",
  orderSummary: "/orderSummary/:hrmrUserId",
  activityChecklistRoute: "/activity-checklist",
  departmentsRoute: "/department",
  manageDocumentsRoute: "/manage-documents",
  serviceSitesRoute: "/service-sites",
  organizationalContactsRoute: "/organizational-contacts",
  trainingLibraryRoute: "/training-library",
  trainingReportRoute: "/training-report",
  trainingDetailsReportRoute: "/training-report/:trainingId/:filterType/:year",
  expirablesRoute: "/expirables",
  manageProfileRoute: "/manage-profile",
  employeeCredentialingProfileRoute: "/employee-credentialing-profile",
  employeeFiles: "/employee-files",
  docuSignReport: "/docu-sign-report",
  clinicalStaff: "/clinical-staff",
  nonClinicalStaff: "/non-clinical-staff",
  setupIntegrationsRoute: "/setup-integrations",
  dashboardInitialRoute: "/dashboard-initial",
  dashboardReCredRoute: "/dashboard-re-cred",
  importExclusionData: "/import-exclusion-data",
  reqToCredentialing: "/request-to-credentialing",
};

export default routesNames;
